import React from "react";
import { Helmet } from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Helmet title={" 404 NOT FOUND | " + data.site.siteMetadata.title} />
        <section className='section'>
          <div className='container'>
            <div className='content has-text-centered'>
              <h1 className='orbitron'>
                <span style={{ fontSize: "7vw" }}>404</span>
                <br />
                <span style={{ fontSize: "5vw" }}>NOT FOUND</span>
              </h1>
              <p>お探しのページは見つかりませんでした。</p>
              <div className='column is-12'>
                <Link className='button is-medium' to='/'>
                  トップページへ戻る
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )}
  />
);

export default NotFoundPage;
